.relative {
  position: relative;
}

.x-input-text:has(+ .x-input-btn .tooltip) {
  padding-right: 44px;
}

.x-input-btn {
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
}

.x-input-icon {
  font-size: 24px;
}

/* Tooltip container */
.tooltip {
  position: relative;
  width: 24px;
  height: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
}

/* Tooltip text */
.tooltip .tooltip-text {
  /* visibility: hidden; */
  width: 120px;
  background-color: #555;
  color: #fff;
  text-align: center;
  padding: 5px 0;
  border-radius: 6px;

  /* Position the tooltip text */
  position: absolute;
  z-index: 1;
  top: 125%;
  left: 50%;
  margin-left: -60px;

  /* Fade in tooltip */
  opacity: 0;
  transition: opacity 0.3s;
}

/* Tooltip arrow */
.tooltip .tooltip-text::after {
  content: "";
  position: absolute;
  bottom: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: transparent transparent #555 transparent;
}

.x-dialog-row {
  padding: 12px 16px;
}
.x-dialog-row:not(:last-child) {
  border-bottom: 1px solid #1a1a1a1f;
}

.x-dialog-row .item {
  /* margin-top: 4px; */
}

.x-dialog-row.grid {
  display: grid;
  gap: 4px;
}

li {
  padding: 4px 0;
}

@media screen and (min-width: 768px) {
  .x-nav {
    border-radius: 24px 24px 0 0;
  }

  /* Show the tooltip text when you mouse over the tooltip container */
  .tooltip:hover .tooltip-text {
    visibility: visible;
    opacity: 1;
  }

  .x-dialog-row {
    padding-right: 0;
    padding-left: 0;
  }

  .x-dialog-row.grid {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }

  .x-dialog-row .item {
    margin-top: 0;
  }
}
